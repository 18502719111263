import { ComponentType } from 'react';

const pageLoader = (
  loadComponent: () => Promise<{ default: ComponentType<any> }>,
  exportKey: string = 'default',
) => {
  return (): Promise<{ default: ComponentType<any> }> =>
    new Promise((resolve, reject) => {
      loadComponent()
        .then((module: any) => resolve({ default: module[exportKey] }))
        .catch((error: object) => {
          reject(error);
        });
    });
};

export default pageLoader;
