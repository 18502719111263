import { FC, ReactNode, Suspense } from 'react';
import DefaultSuspenseFallback from '@templates/SuspenseFallback';

export type SuspenseWrapperProps = {
  suspenseFallback: ReactNode;
  children: ReactNode;
};

const SuspenseWrapper: FC<SuspenseWrapperProps> = ({
  suspenseFallback,
  children,
}) => {
  return (
    <Suspense fallback={suspenseFallback ?? <DefaultSuspenseFallback />}>
      {children}
    </Suspense>
  );
};

export default SuspenseWrapper;
