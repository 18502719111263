export const SUPPORTED_LOCALES = [
  {
    value: 'en-US',
    label: 'ENG',
  },
  {
    value: 'ja-JP',
    label: 'JPN',
  },
] as const;

export enum SupportedLocale {
  'en-US' = 'en-US',
  'ja-JP' = 'ja-JP',
}
